@import '../../marketplace';

.modal {
  /* @apply --marketplaceModalInMobileBaseStyles; */
  border-radius: 8px;
  height: auto;
  width: 720px;
  max-width: 100%;
  background-color: white;
  position: fixed;

  @media (--viewportMedium) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.main {
  display: flex;
}
.image {
  border-radius: 8px 0px 0px 8px;
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}
.content {
  flex-direction: column;
  margin: 20px;
  /* min-width: 300px; */
  /* max-height: 475px; */

  @media (--viewportMedium) {
    margin: 30px;
  }
}
.title {
  color: #0d6786;
  font-size: 24px;
  line-height: 32px;
  padding: 40px 0 20px;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 28px;
    line-height: 38px;
  }
}
.description {
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  padding: 0 0 20px;
  font-weight: 300;

  @media (--viewportMedium) {
    font-size: 18px;
  }
}
.block {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  min-height: 39px;
  padding-left: 10px;
  max-width: 400px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.link {
  font-weight: 700;
}

.imageContainer {
  margin: 0 10px 0 0;
  width: 25px;
}

.chatIcon {
  width: 25px;
  height: 19px;
}

.optionText {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  margin: 0;
  padding: 0;

  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 24px;
  }
}
