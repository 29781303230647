@import '../../marketplace.css';

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  display: none;
  width: 100%;
  position: relative;

  @media (--viewportLarge) {
    display: block;
    max-height: 268px;
    overflow-y: hidden;
  }
}

.bookingInfoContainer {
  padding: 20px;
  display: flex;
  flex-direction: row;

@media (--viewportMedium) {
  border: 1px solid var(--borderColorGray);
  border-radius: 4px;
  margin: 0 0 30px;
}
}

.detailCardImage {
  display: block;
  max-width: 71px;
  max-height: 71px;
  overflow-y: hidden;
  position: relative;
  width: 100%;

  @media (--viewportMedium) {
    max-width: 88px;
    max-height: 88px;
  }
}

.detailCardWrapper {
  padding-bottom: 100%; /* 1:1 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: 4px;
}

.detailCardImageRoot {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    margin-top: -32px;
    padding: 2px 0;
  }
  @media (--viewportLarge) {
    margin-left: 48px;
    width: unset;
    padding: 2px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  /* display: none; */

  @media (--viewportLarge) {
    display: block;
  }
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 6px;
    padding-bottom: 2px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriodText {
  @apply --marketplaceSmallFontStyles;
  flex: 1;
  font-size: 15px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  font-weight: 500;

  @media (--viewportMedium) {
    line-height: 24px;
    font-size: 16px;
  }
}

.bookingUpdatedPeriodText {
  @apply --marketplaceSmallFontStyles;
  font-size: 16px;
  padding: 10px 0 0;
  /*flex: 0 0 100%;*/
  max-width: 100%;
  margin: 0;
}

.bookingUpdatedPeriodTitle {
  color: var(--marketplaceColorDark);
  margin-top: 10px;
  display: block;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 10px;
}

.bookingListingTitle {
  font-size: 20px;
  line-height: 24px;
  color: var(--marketplaceColorDark);
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  padding: 0 0 5px;

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 32px;
  }
}
